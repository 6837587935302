import React from 'react';
import { NextSeo } from 'next-seo';
import { GetServerSideProps } from 'next';
import { getStoryblokApi, ISbStoriesParams, StoryblokComponent } from '@storyblok/react';

/**
 * NOTE: this is a duplicate of women/index.tsx so any changes made here, should be made in women (& men/index.tsx)
 */

export default function Page({ story, ...rest }) {
  return (
    <>
      <NextSeo title="" />
      <StoryblokComponent blok={story.content} />
    </>
  );
}

export async function getStaticProps({ params }) {
  let slug = 'women';

  const storyblokApi = getStoryblokApi();

  let sbParams: ISbStoriesParams = {
    // load the draft version
    version: 'published', // or 'published'
    resolve_links: 'url',
  };

  let { data } = await storyblokApi.get(`cdn/stories/${slug}`, sbParams);

  // let { data: config } = await storyblokApi.get('cdn/stories/config');

  return {
    props: {
      story: data ? data.story : false,
      key: data ? data.story.id : false,
      // config: config ? config.story : false,
    },
    revalidate: 3600, // revalidate every hour
  };
}
